
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import OuterInputComplete from '@/components/organisms/dms/OuterInputComplete/OuterInputComplete.vue'
import Breadcrumb from '@/components/organisms/dms/common/Breadcrumb.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
export default defineComponent({
  components: {
    OuterInputComplete,
    Breadcrumb,
    DssIcon,
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const reuseFlag = store.getters.reuseFlag ? store.getters.reuseFlag : false
    const list = [
      { text: 'ファイルをアップロード', numFlag: true },
      { text: 'メタデータを入力', numFlag: true },
      { text: '内容確認', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    const reuseList = [
      { text: '再利用する資料を選択', numFlag: true },
      { text: 'メタデータを入力', numFlag: true },
      { text: 'ファイルをアップロード', numFlag: true },
      { text: '内容確認', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    return {
      router,
      list,
      reuseList,
      reuseFlag,
    }
  },
})
