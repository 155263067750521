
import { defineComponent, onMounted } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'OuterInputComplete',
  components: {
  },
  props: {
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const confirmed = route.params.confirmed === 'true'
    onMounted(async () => {
      if (!confirmed) {
        await router.push('/dms/outer')
      }
    })
    // 画面再読み込み、ブラウザバックなどの操作制御
    onBeforeRouteLeave(async (to, from, next) => {
      if (to.name === 'OuterInputConfirmPage') {
        window.alert('内容確認画面には戻れません。')
        next(false)
      } else {
        next()
      }
    })

    return {
    }
  },
})
